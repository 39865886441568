<template>
  <div id="body">
    <div class="fatherBox">
      <div class="headerBox">
        <p>数据列表</p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <el-button type="primary" @click="(dialogVisibles = true)">导出数据</el-button>
      </div>
      <div style="text-align: left;width:93%">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="视力档案" name="one">
        <div class="query">
          <div class="queryLeft">
            <div style="float: left ;width: fit-content;margin-right: 10px;">
              年级分类:
              <el-select v-model="gradeid" clearable style="width: 150px;">
                <el-option v-for="(item, index) in gradeList" :key="index" :label="item.TypeName" :value="item.id">
                </el-option>
              </el-select>
            </div>&nbsp;&nbsp;
            <div>
              <el-button @click="reset()">重置</el-button>
              <el-button type="primary" @click="classListDataSearchfirst()">查询</el-button>
            </div>
          </div>
          <div class="queryRight" style="display: flex;">
            <div class="dataBox" @click="schoolEchart()">
              <div class="num">{{ nowMyopialv }}</div>
              <div class="text">全校近视率</div>
            </div>
            <div class="dataBox1">
              <div class="num">{{ arealhv }}</div>
              <div class="text">全区近视率</div>
            </div>
          </div>
        </div>

        <el-table :data="ClassInfos" v-loading="loading" @sort-change="sortChange">
          <el-table-column label="年级" prop="TypeName" width="auto" align="center" sortable="custom"></el-table-column>
          <el-table-column label="班级名称" prop="ClassName" width="auto" align="center"></el-table-column>
          <el-table-column label="注册人数" prop="snum" width="auto" align="center" sortable="custom"></el-table-column>
          <el-table-column label="半年内检测率" prop="hytest" width="auto" align="center" sortable="custom"></el-table-column>
          <el-table-column label="班级视力近视率" prop="classjsl" width="auto" align="center"
            sortable="custom"></el-table-column>
          <el-table-column label="全年级近视率" prop="gradejsl" width="auto" align="center"
            sortable="custom"></el-table-column>
          <el-table-column label="年级排名" prop="ClassTypeRank" width="auto" align="center"
            sortable="custom"></el-table-column>
          <el-table-column label="学校排名" prop="SchoolRank" width="auto" align="center"
            sortable="custom"></el-table-column>
          <el-table-column label="检测时间" prop="test_time" width="auto" align="center"
            sortable="custom"></el-table-column>
          <el-table-column label="操作" fixed="right" width="auto" align="center">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" plain @click="doView(scope.$index, scope.row)">查看详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div v-show="CountNum > 0">
          <el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
            layout="total,prev, pager, next, jumper" :current-page="currpage" :total="CountNum" style="margin-top:20px">
          </el-pagination>
        </div></el-tab-pane>
        
        <el-tab-pane label="健康档案" name="two"><div class="query">
          <div class="queryLeft">
            <div style="float: left ;width: fit-content;margin-right: 10px;">
              年级分类:
              <el-select v-model="gradeid" clearable style="width: 150px;">
                <el-option v-for="(item, index) in gradeList" :key="index" :label="item.TypeName" :value="item.id">
                </el-option>
              </el-select>
            </div>&nbsp;&nbsp;
            <div>
              <el-button @click="reset()">重置</el-button>
              <el-button type="primary" @click="classListDataSearchfirst()">查询</el-button>
            </div>
          </div>
          <div class="queryRight" style="display: flex;">
            <div class="dataBox" @click="schoolEchart()">
              <div class="num">{{ schoolFat }}%</div>
              <div class="text">全校肥胖率</div>
            </div>
          </div>
        </div>

        <el-table :data="healthList" v-loading="loading" @sort-change="sortChange">
          <el-table-column label="年级" prop="TypeName" width="auto" align="center" sortable="custom"></el-table-column>
          <el-table-column label="班级" prop="ClassName" width="auto" align="center"></el-table-column>
          <el-table-column label="班级人数" prop="classStuCount" width="auto" align="center" sortable="custom"></el-table-column>
          <el-table-column label="班级平均身高(cm)" prop="class_height_avg" width="auto" align="center" sortable="custom"></el-table-column>
          <el-table-column label="年级平均身高(cm)" prop="type_height_avg" width="auto" align="center"
            sortable="custom"></el-table-column>
          <el-table-column label="班级平均体重(kg)" prop="class_weight_avg" width="auto" align="center"
            sortable="custom"></el-table-column>
          <el-table-column label="年级平均体重(kg)" prop="type_weight_avg" width="auto" align="center"
            sortable="custom"></el-table-column>
          <el-table-column label="班级肥胖率" prop="class_big" width="auto" align="center"
            sortable="custom"></el-table-column>
          <el-table-column label="年级肥胖率" prop="type_big" width="auto" align="center"
            sortable="custom"></el-table-column>
          <el-table-column label="操作" fixed="right" width="auto" align="center">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" plain @click="doView(scope.$index, scope.row)">查看详情
              </el-button>
            </template>
          </el-table-column>
        </el-table></el-tab-pane>
        <el-tab-pane label="运动记录" name="three"><div class="query">
          <div class="queryLeft">
            <div style="float: left ;width: fit-content;margin-right: 10px;">
              年级分类:
              <el-select v-model="gradeid" clearable style="width: 150px;">
                <el-option v-for="(item, index) in gradeList" :key="index" :label="item.TypeName" :value="item.id">
                </el-option>
              </el-select>
              日期：<el-date-picker value-format="yyyy-MM-dd" style="width: 150px;" v-model="healthDay" align="right" type="date"
							placeholder="选择日期" :picker-options="pickerOptions">
						</el-date-picker>
              <el-link style="margin: 0 10px;" @click="onChangeDayType(item.value)" v-for="item in dayOptions" :type="item.value==dayType ? 'primary' : ''">{{ item.label }}</el-link>
            </div>&nbsp;&nbsp;
            <div>
              <el-button @click="reset()">重置</el-button>
              <el-button type="primary" @click="classListDataSearchfirst()">查询</el-button>
            </div>
          </div>
          <div class="queryRight" style="display: flex;">
            <div class="dataBox" @click="schoolEchart()">
              <div class="num">{{ schoolFat }}%</div>
              <div class="text">全校肥胖率</div>
            </div>
          </div>
        </div>

        <el-table :data="healthList" v-loading="loading" @sort-change="sortChange">
          <el-table-column label="年级" prop="TypeName" width="auto" align="center" sortable="custom"></el-table-column>
          <el-table-column label="班级" prop="ClassName" width="auto" align="center"></el-table-column>
          <el-table-column label="班级人数" prop="snum" width="auto" align="center" sortable="custom"></el-table-column>
          <el-table-column label="班级平均步数" prop="walk" width="auto" align="center" sortable="custom"></el-table-column>
          <el-table-column label="班级平均运动量(卡路里)" prop="calorie" width="auto" align="center"
            sortable="custom"></el-table-column>
          <el-table-column label="班级达标率" prop="complete" width="auto" align="center"
            sortable="custom"></el-table-column>
          <el-table-column label="班级肥胖率" prop="class_big" width="auto" align="center"
            sortable="custom"></el-table-column>
          <el-table-column label="年级排名" prop="rank" width="auto" align="center"
            sortable="custom"></el-table-column>
          <el-table-column label="操作" fixed="right" width="auto" align="center">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" plain @click="doView(scope.$index, scope.row)">查看详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        </el-tab-pane>
      </el-tabs></div>
    </div>
    <el-dialog :visible.sync="dialogVisible" title='全校近视率'>
      <div id="main" style="width: 98%; height: 350px; background: white"></div>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisibles" title='导出数据' width="500px">
      <div>
        <el-form label-width="80px" class="demo-ruleForm" label-position="left">
          <el-form-item label="年级">
            <el-select v-model="form.gid" @change="classSelect" clearable style="width: 350px;" placeholder="全部年级">
              <el-option v-for="(item, index) in gradeList" :key="index" :label="item.TypeName" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="班级">
            <el-select v-model="form.cid" clearable style="width: 350px;" placeholder="全部班级">
              <el-option v-for="(item, index) in classList" :key="index" :label="item.ClassName" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="日期">
            <el-date-picker style="width: 350px;" v-model="form.day" value-format="yyyy-MM-dd" type="date"
              :picker-options="pickerOptions" placeholder="请选择截止日期">
            </el-date-picker>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibles = false">取 消</el-button>
          <el-button type="primary" @click="doExport">确 定</el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  classListData,
  statistics,
  schoolGoodPic,
  excelDaochueye,
  motionClass
} from '@/api/SchoolApi.js'
import {
  gradeClass
} from '@/api/TeacherApi.js'
import {
  mapGetters
} from 'vuex'
export default {
  computed: {
    ...mapGetters(['Status'])
  },
  data() {
    return {
      healthDay: '',
      dayType: 1,
      dayOptions: [{
        value: 1,
        label: '今日'
      }, {
        value: 7,
        label: '近一周'
      }, {
        value: 30,
        label: '近一月'
      }, {
        value: 90,
        label: '近三月'
      }],
      schoolFat: '',
      healthList: [],
      activeName: 'one',
      ClassInfos: [],
      loading: false, // 加载框
      pagesize: 6, // 每页显示条目个数
      currpage: 1, // 初始页
      CountNum: 0, // 总条数
      gradeid: '', // 年级查询id
      gradeList: [], // 年级数组
      classid: '', // 班级查询id
      classList: [], // 班级数组
      nowMyopialv: '',
      arealhv: '',
      Series: [], // 折线图Y轴数据
      Dates: [], // 折线图X轴数据
      dialogVisible: false,
      dialogVisibles: false,
      daterange: [],
      form: {
        day: '',
        gid: '', // 年级查询id
        cid: '', // 班级查询id
        bstatus: 1
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      order: '', // 排序
      ordertype: '' // 排序分类
    }
  },
  watch: {
    healthDay(val) {
      this.dayType = ''
      this.getHealthList(val)
    }
  },
  mounted() {
    this.ClassList(1) // 获取数据列表
    this.gradeListvue() // 获取年级信息
    this.DataList() // 统计数据
    this.getHealthList()
  },
  methods: {
    reset() { // 重置按钮
      this.dayType = 1
      this.healthDay = ''
      this.getHealthList()
      this.classList = []
      this.gradeid = ''
      this.currpage = 1
      this.ClassList(this.currpage)
    },
    ClassList(currpage) { // 班级数据管理列表
      this.loading = true
      const data = {
        page: currpage, // 当前页数
        limit: this.pagesize, //	每页条数
        order: this.order,
        ordertype: this.ordertype,
        grade: this.gradeid
      }
      classListData(data).then(res => {
        this.ClassInfos = res.data.List
        this.CountNum = parseInt(res.data.CountNum) // 总条数
        this.loading = false
      }).catch(err => {
        console.log(err)
      })
    },
    onChangeDayType(value) {
      this.dayType = value
      this.getHealthList(value)
    },
    getHealthList(day) {
      this.loading = true
      const data = {
        day:day
      }
      motionClass(data).then(res => {
        this.loading = false
        this.healthList = res.data.list
        this.schoolFat = res.data.bigRate
      }).catch(err => {
        console.log(err)
      })
    },
    DataList() { // 数据统计
      statistics().then(res => {
        if (res.status.code == 1) {
          this.nowMyopialv = res.data.nowMyopialv // 全校近视率
          this.arealhv = res.data.arealhv // 全区近视率
        } else {
          console.log('失败')
          this.nowMyopialv = '暂无'
          this.arealhv = '暂无'
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 查看详情
    doView(index, row) {
      this.$router.push({ // 页面跳转
        name: 'classStudentDataList', // 往这个页面跳转
        params: { // 跳转传递的参数
          gradeId: row.ClassType,
          classId: row.ClassID
        }
      })
    },
    handleCurrentChange(val) {
      if (this.gradeid == '') {
        this.ClassList(val)
        this.currpage = val
      } else {
        this.classListDataSearch(val) // 当有姓名条件时 分页操作
      }
    },
    classListDataSearchfirst(val = 1) { // 只有当点击查询时候走这个方法
      const data = {
        page: val,
        limit: this.pagesize,
        order: this.order,
        ordertype: this.ordertype,
        grade: this.gradeid
      }
      classListData(data).then(res => {
        this.currpage = 1
        this.ClassInfos = res.data.List
        this.CountNum = parseInt(res.data.CountNum)
        this.loading = false
      }).catch(err => {
        console.log(err)
      })

    },

    classListDataSearch(val) { // 当第一次 点了查询条件后 分页走这个方法
      this.currpage = val
      const data = {
        page: this.currpage, // 当前页数
        limit: this.pagesize, //	每页条数
        order: this.order,
        ordertype: this.ordertype,
        grade: this.gradeid
      }

      classListData(data).then(res => {
        this.ClassInfos = res.data.List
        this.CountNum = parseInt(res.data.CountNum)
        this.loading = false
      }).catch(err => {
        console.log(err)
      })
    },
    drawChart() {
      const that = this
      const myChart = this.$echarts.init(document.getElementById('main'))
      const options = {
        // title:{text:'Echarts数据'},
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['近视率']
        },
        xAxis: {
          data: that.Dates
        },
        yAxis: {},
        series: [{
          name: '近视率',
          type: 'line',
          data: that.Series
        }]
      }
      myChart.setOption(options)
    },
    schoolEchart() { // 全校近视率-折线图
      this.dialogVisible = true
      schoolGoodPic().then(res => {
        // console.log(res)
        this.Series = res.data.jnum
        this.Dates = res.data.date
        this.drawChart()
      }).catch(err => {
        console.log(err)
      })
    },
    doExport() { // 导出
      if (!this.form.day) {
        this.$message.error('请选择日期！')
        return false
      }

      excelDaochueye(this.form).then(res => {
        if (res.status.code == 1) {
          window.open(Vue.prototype.$url + res.data)
          this.dialogVisibles = false
        } else {
          this.$message.error(res.status.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    gradeListvue() { // 年级列表
      const data = {
        type: 1 // 没用
      }
      gradeClass(data).then(res => {
        this.gradeList = res.data
      }).catch(err => {
        console.log(err)
      })
    },
    classSelect() { // 班级列表
      this.form.cid = ''
      this.classList = []
      const data = {
        gid: this.form.gid
      }

      if (this.gid != '') {
        gradeClass(data).then(res => {
          this.classList = res.data
        }).catch(err => {
          console.log(err)
        })
      }
    },
    sortChange(column) { //排序

      if (column.order != null) {
        this.order = column.order.replace('ending', '')
      } else {
        this.order = 'asc';
      }

      this.ordertype = column.prop
      this.ClassList(this.currpage);
    },

  }

}
</script>

<style lang="scss" scoped>
#body {
  background: #f0f2f5;
}

.fatherBox {
  width: 98%;
  // height: 800px;
  display: flex;
  background: white;
  margin: 0 auto;
  flex-direction: column;
  justify-content: center;

  .headerBox {
    display: flex;
    height: 52px;
    width: 98%;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #e9e9e9;

    p {
      font-size: 16px;
      font-family: 萍方-简;
      font-weight: normal;
      color: #000000;
      margin-left: 32px;
    }

    .btn {
      display: flex;
      align-items: center;
      width: 109px;
      height: 32px;
      opacity: 1;
      border-radius: 2px;
    }
  }

  .del {
    float: left,

  }

  .query {
    width: 98%;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    padding-left: 32px;

    .queryLeft {
      width: 75%;
      display: flex;
      flex-wrap: wrap;

      div {
        width: 300px;
        line-height: 50px;
        text-align: left;

        .input,
        .select {
          width: 60%;
        }
      }
    }
  }
}

.dataBox {
  padding: 5px 20px;
  font-size: 18px;
  cursor: pointer;

  .num {
    color: #409EFF
  }
}

.dataBox1 {
  padding: 5px 20px;
  font-size: 18px;

  .num {
    color: #409EFF
  }
}
</style>
